@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 13px;
  -webkit-print-color-adjust: exact;

  @screen md {
    font-size: 15px;
  }

  @screen lg {
    font-size: 18px;
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
}

body {
  font-family: Arial, Helvetica, sans-serif;
  @apply bg-orange-50;
}

svg {
  display: block;
  width: 100%;
  height: 100%;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
  width: 0;
  scrollbar-width: 0;
}
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.vimeo {
  position: relative;
  padding: 56.25% 0 0 0;
}

.vimeo iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #ededed;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #717171;
  border-radius: 12px;
}

.horizonal::-webkit-scrollbar {
  height: 3px;
}

/**
*  IMAGE CLOUD
**/
/* #image-cloud svg {
  width: unset;
  height: unset;
  margin: 0 auto;
}

.image-cloud-image {
  transition:
    transform 0.3s ease,
    filter 0.3s ease;
}

.image-cloud-active-hover {
  transform: scale(1.4);
  filter: none;
}

.image-cloud-inactive-hover {
  transform: scale(0.95);
  filter: grayscale(100%);
} */

/**
*  IMAGE CLOUD - V2
**/
#collage {
  width: 100%;
  height: 100%;
}

#collage .collage_inner {
  width: 100%;
  height: 100%;
  position: relative;
}

#collage .collage_inner .collage_item {
  width: var(--score);
  height: var(--score);
  padding: 2px;
  position: absolute;
}

#collage .collage_inner .collage_item .collage_item_inner {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

#collage .collage_inner .collage_item .collage_item_inner img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: static;
}

/* #collage .collage_item:hover {
  transform: scale(1.4);
  filter: none;
  z-index: 9999;
} */

.list ol {
  list-style: decimal;
  padding-left: 1.5rem;
}
